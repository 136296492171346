import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Divider,
  Hidden,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { THEMES } from "src/constants";
import Account from "./Account";
import Settings from "./Settings";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.common.black,
        }
      : {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.common.white,
        }),
  },
  toolbar: {
    minHeight: 64,
  },
}));

const TopBar = ({ className, onNavHandle, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden xsDown>
          <Box mr={2}>
            <RouterLink to="/">
              <img alt="Logo" src={"/static/logo.png"} />
            </RouterLink>
          </Box>
        </Hidden>
        <IconButton color="inherit" onClick={onNavHandle}>
          <SvgIcon fontSize="small">
            <MenuIcon />
          </SvgIcon>
        </IconButton>

        <Box ml={2} flexGrow={1} />

        <Box mr={0.5}>
          <Settings />
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginTop: 12, marginBottom: 12 }}
        />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onNavHandle: PropTypes.func,
};

TopBar.defaultProps = {
  onNavHandle: () => {},
};

export default TopBar;
