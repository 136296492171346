import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";

export const renderRoutes = (routes = []) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : route.redirect ? (
                      <Redirect to={route.redirect} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const routes = [
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFoundView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login2",
    redirect: "/login",
  },
  {
    path: "/app",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/app/reports/dashboard",
        component: lazy(() => import("src/views/reports/DashboardView")),
      },
      {
        exact: true,
        path: "/app/reports/operation",
        component: lazy(() => import("src/views/reports/OperationView")),
      },
      {
        exact: true,
        path: "/app/reports/reports/:id",
        component: lazy(() => import("src/views/reports/ReportView")),
      },
      {
        exact: true,
        path: "/app/reports",
        component: lazy(() => import("src/views/reports/OperationView")),
      },
      {
        exact: true,
        path: "/app",
        component: () => <Redirect to="/app/reports/operation" />,
      },
      {
        exact: true,
        path: "/app/account",
        component: lazy(() => import("src/views/account/AccountView")),
      },
      {
        exact: true,
        path: "/app/management/users",
        component: lazy(() => import("src/views/user/UserListView")),
      },
      {
        exact: true,
        path: "/app/management/users/:id/edit/:origin",
        component: lazy(() => import("src/views/user/UserEditView")),
      },
      {
        exact: true,
        path: "/app/management/users/:id/new",
        component: lazy(() => import("src/views/user/UserEditView")),
      },
      {
        exact: true,
        path: "/app/management/business",
        component: lazy(() => import("src/views/business/BusinessListView")),
      },
      {
        exact: true,
        path: "/app/management/business/:id/edit",
        component: lazy(() => import("src/views/business/BusinessEditView")),
      },
      {
        exact: true,
        path: "/app/management/process",
        component: lazy(() => import("src/views/process/ProcessListView")),
      },
      {
        exact: true,
        path:
          "/app/management/process/:business/:id/:idPlaceOrigin/:idProcessType/:idDestiny/edit",
        component: lazy(() => import("src/views/process/ProcessEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/process/history/:id",
        component: lazy(() =>
          import("src/views/process/ProcessHistoryListView")
        ),
      },
      {
        exact: true,
        path: "/app/management/transports/scheduling",
        component: lazy(() => import("src/views/transport/Scheduling")),
      },
      {
        exact: true,
        path: "/app/management/transports/scheduling/:idTab/:idProcess",
        component: lazy(() => import("src/views/transport/Scheduling")),
      },
      {
        exact: true,
        path: "/app/management/transports/records",
        component: lazy(() =>
          import("src/views/transport/Record/RecordListView")
        ),
      },
      {
        exact: true,
        path:
          "/app/management/transports/records/:idRecord/:idProcess/:idContainer/:idScheduling/:idStatus/:idUpload/edit",
        component: lazy(() =>
          import("src/views/transport/Record/RecordEditView")
        ),
      },
      {
        exact: true,
        path: "/app/management/transports/uploads_old",
        component: lazy(() =>
          import("src/views/transport/Upload/UploadListView")
        ),
      },
      {
        exact: true,
        path:
          "/app/management/transports/uploads_old/:idUpload/:idProcess/:idContainer/:idProvider/edit",
        component: lazy(() =>
          import("src/views/transport/Upload/UploadEditView")
        ),
      },
      {
        exact: true,
        path: "/app/management/lots",
        component: lazy(() =>
          import("src/views/uploadLots/UploadLotsListView")
        ),
      },
      {
        exact: true,
        path: "/app/management/lots/initial_balance",
        component: lazy(() =>
          import("src/views/uploadLots/InitialBalanceEditView")
        ),
      },
      {
        exact: true,
        path: "/app/management/lots/:idUploadLot/edit",
        component: lazy(() => import("src/views/uploadLots/UploadLotEditView")),
      },
      {
        exact: true,
        path: "/app/management/occurrences",
        component: lazy(() =>
          import("src/views/occurrence/OccurrenceProvidersListView")
        ),
      },
      {
        exact: true,
        path:
          "/app/management/occurrences/list/:idProvider/:DateStart/:DateEnd",
        component: lazy(() =>
          import("src/views/occurrence/OccurrenceListView")
        ),
      },
      {
        exact: true,
        path: "/app/management/occurrences/:id/:idProvider/edit",
        component: lazy(() =>
          import("src/views/occurrence/OccurrenceEditView")
        ),
      },
      {
        exact: true,
        path: "/app/data/register/places",
        component: lazy(() => import("src/views/place/PlaceListView")),
      },
      {
        exact: true,
        path: "/app/data/register/places/:id/edit",
        component: lazy(() => import("src/views/place/PlaceEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/customers",
        component: lazy(() => import("src/views/customer/CustomerListView")),
      },
      {
        exact: true,
        path: "/app/data/register/customers/:id/edit",
        component: lazy(() => import("src/views/customer/CustomerEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/carriers",
        component: lazy(() => import("src/views/carrier/CarrierListView")),
      },
      {
        exact: true,
        path: "/app/data/register/carriers/:id/edit",
        component: lazy(() => import("src/views/carrier/CarrierEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/providers",
        component: lazy(() => import("src/views/provider/ProviderListView")),
      },
      {
        exact: true,
        path: "/app/data/register/providers/:id/edit",
        component: lazy(() => import("src/views/provider/ProviderEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/profiles",
        component: lazy(() => import("src/views/profile/ProfileListView")),
      },
      {
        exact: true,
        path: "/app/data/register/profiles/:id/edit",
        component: lazy(() => import("src/views/profile/ProfileEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/fleets",
        component: lazy(() => import("src/views/fleet/FleetListView")),
      },
      {
        exact: true,
        path: "/app/data/register/fleets/:id/edit",
        component: lazy(() => import("src/views/fleet/FleetEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/trollers",
        component: lazy(() => import("src/views/troller/TrollerListView")),
      },
      {
        exact: true,
        path: "/app/data/register/trollers/:id/edit",
        component: lazy(() => import("src/views/troller/TrollerEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/fleets/history/:id",
        component: lazy(() => import("src/views/fleet/FleetHistoryListView")),
      },
      {
        exact: true,
        path: "/app/data/register/destinations",
        component: lazy(() => import("src/views/destiny/DestinyListView")),
      },
      {
        exact: true,
        path: "/app/data/register/destinations/:id/edit",
        component: lazy(() => import("src/views/destiny/DestinyEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/locations",
        component: lazy(() => import("src/views/location/LocationListView")),
      },
      {
        exact: true,
        path: "/app/data/register/locations/:id/edit",
        component: lazy(() => import("src/views/location/LocationEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/locations/proposals/:id/list",
        component: lazy(() =>
          import("src/views/location/proposal/ProposalListView")
        ),
      },
      {
        exact: true,
        path:
          "/app/data/register/locations/proposals/:id/:idOtherLocation/edit",
        component: lazy(() =>
          import("src/views/location/proposal/ProposalEditView")
        ),
      },
      {
        exact: true,
        path: "/app/data/register/materials",
        component: lazy(() => import("src/views/material/MaterialListView")),
      },
      {
        exact: true,
        path: "/app/data/register/materials/:id/edit",
        component: lazy(() => import("src/views/material/MaterialEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/price_table",
        component: lazy(() =>
          import("src/views/priceTable/PriceTableListView")
        ),
      },
      {
        exact: true,
        path: "/app/data/register/price_table/:id/edit",
        component: lazy(() =>
          import("src/views/priceTable/PriceTableEditView")
        ),
      },
      {
        exact: true,
        path: "/app/data/register/shipowners",
        component: lazy(() => import("src/views/shipowner/ShipownerListView")),
      },
      {
        exact: true,
        path: "/app/data/register/shipowners/:id/edit",
        component: lazy(() => import("src/views/shipowner/ShipownerEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/ships",
        component: lazy(() => import("src/views/ship/ShipListView")),
      },
      {
        exact: true,
        path: "/app/data/register/ships/:id/edit",
        component: lazy(() => import("src/views/ship/ShipEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/shipowners/proposals/:id/list",
        component: lazy(() =>
          import("src/views/shipowner/proposal/ProposalListView")
        ),
      },
      {
        exact: true,
        path: "/app/data/register/shipowners/proposals/:id/:idShipowner/edit",
        component: lazy(() =>
          import("src/views/shipowner/proposal/ProposalEditView")
        ),
      },
      {
        exact: true,
        path: "/app/data/register/containers",
        component: lazy(() => import("src/views/container/ContainerListView")),
      },
      {
        exact: true,
        path: "/app/data/register/containers/:id/edit",
        component: lazy(() => import("src/views/container/ContainerEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/items",
        component: lazy(() => import("src/views/item/ItemListView")),
      },
      {
        exact: true,
        path: "/app/data/register/items/:id/edit",
        component: lazy(() => import("src/views/item/ItemEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/activities",
        component: lazy(() => import("src/views/activity/ActivityListView")),
      },
      {
        exact: true,
        path: "/app/data/register/activities/:id/edit",
        component: lazy(() => import("src/views/activity/ActivityEditView")),
      },
      {
        exact: true,
        path: "/app/data/register/goals",
        component: lazy(() => import("src/views/goal/GoalListView")),
      },
      {
        exact: true,
        path: "/app/data/register/goals/:id/edit",
        component: lazy(() => import("src/views/goal/GoalEditView")),
      },
      {
        exact: true,
        path: "/app/management/daily_goal",
        component: lazy(() => import("src/views/dailyGoal/DailyGoalListView")),
      },
      {
        exact: true,
        path: "/app/management/daily_goal/edit",
        component: lazy(() => import("src/views/dailyGoal/DailyGoalEditView")),
      },
      {
        exact: true,
        path: "/app/financial/sales_invoice",
        component: lazy(() =>
          import("src/views/salesInvoice/SalesInvoiceListView")
        ),
      },
      {
        exact: true,
        path: "/app/financial/sales_invoice/:id/edit",
        component: lazy(() =>
          import("src/views/salesInvoice/SalesInvoiceEditView")
        ),
      },
      {
        exact: true,
        path: "/app/financial/sales_invoice/:idSaleInvoice/invoices",
        component: lazy(() => import("src/views/salesInvoice/InvoiceSubList")),
      },
      {
        exact: true,
        path:
          "/app/financial/sales_invoice/:idSaleInvoice/invoices/:id/:type/edit",
        component: lazy(() => import("src/views/salesInvoice/InvoiceSubEdit")),
      },
      {
        exact: true,
        path: "/app/management",
        component: () => <Redirect to="/app/management/users" />,
      },
      {
        exact: true,
        path: "/app/data",
        component: () => <Redirect to="/app/data/register/providers" />,
      },
      {
        exact: true,
        path: "/app/data/register",
        component: () => <Redirect to="/app/data/register/providers" />,
      },
      {
        exact: true,
        path: "/app/data/register/companies-full",
        component: lazy(() =>
          import("src/views/companiesFull/CompanyListView")
        ),
      },
      {
        exact: true,
        path: "/app/data/register/companies-full/:id/edit",
        component: lazy(() =>
          import("src/views/companiesFull/CompanyEditView")
        ),
      },
      {
        exact: true,
        path: "/app/data/register/clients",
        component: lazy(() => import("src/views/clients/ClientListView")),
      },
      {
        exact: true,
        path: "/app/data/register/clients/:id/edit",
        component: lazy(() => import("src/views/clients/ClientEditView")),
      },
      {
        exact: true,
        path: "/app/shopping/orders",
        component: lazy(() => import("src/views/order/OrderListView")),
      },
      {
        exact: true,
        path: "/app/shopping/orders/:id/edit",
        component: lazy(() => import("src/views/order/OrderEditView")),
      },
      {
        exact: true,
        path: "/app/shopping/orders/:orderId/budgets",
        component: lazy(() => import("src/views/orderBudgets/OrderListView")),
      },
      {
        exact: true,
        path: "/app/shopping/orders/:orderId/budgets/new",
        component: lazy(() => import("src/views/orderBudgets/OrderEditView")),
      },
      {
        exact: true,
        path: "/app/shopping/approval",
        component: lazy(() => import("src/views/ordersApproval/OrderListView")),
      },
      {
        exact: true,
        path: "/app/shopping/approval/:orderId",
        component: lazy(() =>
          import("src/views/ordersApproval/OrderBudgetListView")
        ),
      },
      {
        exact: true,
        path: "/app/shopping/conference",
        component: lazy(() =>
          import("src/views/ordersConference/OrderListView")
        ),
      },
      {
        exact: true,
        path: "/app/financial/register/types",
        component: lazy(() => import("src/views/types/ListView")),
      },
      {
        exact: true,
        path: "/app/financial/register/types/:id/edit",
        component: lazy(() => import("src/views/types/EditView")),
      },
      {
        exact: true,
        path: "/app/financial/register/natures",
        component: lazy(() => import("src/views/natures/ListView")),
      },
      {
        exact: true,
        path: "/app/financial/register/natures/:id/edit",
        component: lazy(() => import("src/views/natures/EditView")),
      },
      {
        exact: true,
        path: "/app/financial/register/natures/:idNature/sub",
        component: lazy(() => import("src/views/subnatures/ListView")),
      },
      {
        exact: true,
        path: "/app/financial/register/natures/:idNature/sub/:id/edit",
        component: lazy(() => import("src/views/subnatures/EditView")),
      },
      {
        exact: true,
        path: "/app/financial/register/bills-to-pay/:id",
        component: lazy(() => import("src/views/billsToPay/EditView")),
      },
      {
        exact: true,
        path: "/app/financial/register/bills-to-receive/:id",
        component: lazy(() => import("src/views/billsToReceive/EditView")),
      },
      {
        exact: true,
        path: "/app/financial/contas_a_pagar",
        component: lazy(() => import("src/views/billsToPay/ListView")),
      },
      {
        exact: true,
        path: "/app/financial/contas_a_receber",
        component: lazy(() => import("src/views/billsToReceive/ListView")),
      },
      {
        exact: true,
        path: "/app/financial/adiantamento_sucata",
        component: lazy(() => import("src/views/adiantamentoSucata")),
      },
      {
        exact: true,
        path: "/app/financial/adiantamento_sucata",
        component: lazy(() => import("src/views/adiantamentoSucata")),
      },
      {
        exact: true,
        path: "/app/financial/register/adiantamento_sucata",
        component: lazy(() => import("src/views/adiantamentoSucata/EditView")),
      },
      {
        exact: true,
        path: "/app/financial/adiantamento_sucata/:id",
        component: lazy(() => import("src/views/adiantamentoSucata/ListView")),
      },
      {
        exact: true,
        path: "/app/financial/reports",
        component: lazy(() => import("src/views/reports/FinancialView")),
      },
      {
        exact: true,
        path: "/app/financial/report-weekly",
        component: lazy(() =>
          import("src/views/reports/FinancialView/weekly.js")
        ),
      },
      {
        exact: true,
        path: "/app/financial/dashboard",
        component: lazy(() =>
          import("src/views/reports/FinancialView/dashboard.js")
        ),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: "*",
    layout: GuestGuard,
    routes: [
      {
        exact: true,
        path: "/",
        component: AuthGuard,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
