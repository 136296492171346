import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";

const GuestGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    if (user.permissions.includes("Operação - Dashboard")) {
      return <Redirect to="/app/reports/dashboard" />;
    } else if (user.permissions.includes("Financeiro - Dashboard")) {
      return <Redirect to="/app/financial/dashboard" />;
    }

    return <Redirect to="/app" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
