import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  Hidden,
  IconButton,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core";
import useAuth from "src/hooks/useAuth";
import { THEMES } from "src/constants";
import { LogOut } from "react-feather";

const useStyles = makeStyles((theme) => ({
  text: {
    textDecoration: "none",
    ...(theme.name === THEMES.LIGHT
      ? { color: theme.palette.common.black }
      : { color: theme.palette.common.white }),
  },
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = () => {
    try {
      logout();
      history.push("/");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        variant: "error",
      });
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent={"center"}>
      <Hidden xsDown>
        <RouterLink to="/app/account" className={classes.text}>
          <Typography variant="h6" color="inherit">
            Olá, {user.name}
          </Typography>
        </RouterLink>
      </Hidden>

      <Box ml={1}>
        <IconButton onClick={handleLogout} size="small">
          <SvgIcon fontSize="small" color="error">
            <LogOut />
          </SvgIcon>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Account;
