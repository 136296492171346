import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import SplashScreen from "src/components/SplashScreen";
import axios from "src/utils/axios";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  initialise: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email, password, dbId) => {
    try {
      console.clear();
      axios.defaults.headers.common.dbId = dbId;
      const response = await axios.post("signin", {
        email,
        password,
        dbId,
      });

      const data = { ...response.data };
      const accessToken = data.token;

      const user = {
        ...data,
        filterGate: data.id_profile === 5 && +data.id_place_origin !== 99999,
        userProvider: data.id_profile === 8,
      };

      localStorage.setItem("dbId", dbId);
      setSession(accessToken);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    } catch (e) {
      delete axios.defaults.headers.common.dbId;
      throw e.response.data;
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const register = async (email, name, password) => {
    const response = await axios.post("/api/account/register", {
      email,
      name,
      password,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const initialise = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const decoded = jwtDecode(accessToken);

        axios.defaults.headers.common.dbId = decoded.dbId;
        const response = await axios.get(`users/${decoded.id}`);

        const data = { ...response.data[0] };
        const user = {
          ...data,
          filterGate: data.id_profile === 5 && +data.id_place_origin !== 99999,
          userProvider: data.id_profile === 8,
          permissions: data.permissions ? data.permissions.split(",") : [],
        };

        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALISE",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  useEffect(() => {
    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
        initialise,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
