/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Drawer,
  Hidden,
  Link,
  List,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ShoppingBag,
  Truck,
  DollarSign,
  ShoppingCart,
  PlusSquare,
  Grid,
} from "react-feather";
import useAuth from "src/hooks/useAuth";
import NavItem from "./NavItem";

const sections = [
  {
    title: "Operação",
    icon: Grid,
    permission: ["Operação - Dashboard", "Operação - Operação"],
    items: [
      {
        title: "Dashboard",
        href: "/app/reports/dashboard",
        permission: ["Operação - Dashboard"],
      },
      {
        title: "Operação",
        href: "/app/reports/operation",
        permission: ["Operação - Operação"],
      },
    ],
  },
  {
    title: "Comercial",
    icon: ShoppingBag,
    permission: [
      "Comercial - Tabela de Preços",
      "Comercial - Metas",
      "Comercial - Negócios p/ Processos",
      "Comercial - Relatórios - Propostas Vigentes",
      "Comercial - Relatórios - Tabela de Preços",
      "Comercial - Relatórios - Fornecedores",
      "Comercial - Relatórios - Clientes",
    ],
    items: [
      {
        title: "Tabela de Preços",
        href: "/app/data/register/price_table",
        permission: ["Comercial - Tabela de Preços"],
      },
      {
        title: "Metas",
        href: "/app/data/register/goals",
        permission: ["Comercial - Metas"],
      },
      {
        title: "Negócios p/ Processos",
        href: "/app/management/business",
        permission: ["Comercial - Negócios p/ Processos"],
      },
      {
        title: "Relatórios",
        permission: [
          "Comercial - Relatórios - Propostas Vigentes",
          "Comercial - Relatórios - Tabela de Preços",
          "Comercial - Relatórios - Fornecedores",
          "Comercial - Relatórios - Clientes",
        ],
        items: [
          {
            title: "Propostas Vigentes",
            href: "/",
            permission: ["Comercial - Relatórios - Propostas Vigentes"],
          },
          {
            title: "Tabela de Preços",
            href: "/",
            permission: ["Comercial - Relatórios - Tabela de Preços"],
          },
          {
            title: "Fornecedores",
            href: "/",
            permission: ["Comercial - Relatórios - Fornecedores"],
          },
          {
            title: "Clientes",
            href: "/",
            permission: ["Comercial - Relatórios - Clientes"],
          },
        ],
      },
    ],
  },
  {
    title: "Logística",
    icon: Truck,
    permission: [
      "Logística - Processos de Carregamentos",
      "Logística - Programação de Carregamento - Programação Diária",
      "Logística - Carregamentos em Fornecedores",
      "Logística - Transportadoras - Programação de Puxadas",
      "Logística - Transportadoras - Agendamentos",
      "Logística - Transportadoras - Ocorrências",
      "Logística - Entrada de Pequenas Coletas",
      "Logística - Consulta de Containers",
      "Logística - Relatórios - Acompanhamento diário de metas",
      "Logística - Relatórios - Acompanhamento de cabotagem/exportação",
      "Logística - Relatórios - Acompanhamento de NFs de venda",
      "Logística - Relatórios - Carregamento do mês",
      "Logística - Relatórios - Controle de carregamento diário",
      "Logística - Relatórios - Controle de contêineres a chegar",
      "Logística - Relatórios - Controle de entregas na usina",
      "Logística - Relatórios - Controle de processos da Export",
      "Logística - Relatórios - Controle de produtividade",
      "Logística - Relatórios - Localização dos trollers",
      "Logística - Relatórios - Metas do mês",
      "Logística - Relatórios - Ocorrências",
      "Logística - Relatórios - Packlist - cabotagem",
      "Logística - Relatórios - Packlist - exportação",
      "Logística - Relatórios - Puxada do mês - Transnorte",
      "Logística - Relatórios - Status dos cntrs por fornecedor",
      "Logística - Relatórios - Acompanhamento diário de processo",
      "Logística - Relatórios - Movimentação diária",
      "Logística - Relatórios - Controle de custos",
      "Logística - Relatórios - Volume mensal de carregamentos",
      "Logística - Relatórios - Controle mensal de faturamento",
    ],
    items: [
      {
        title: "Processos de Carregamentos",
        href: "/app/management/process",
        permission: ["Logística - Processos de Carregamentos"],
      },
      {
        title: "Programação de Carregamento",
        permission: [
          "Logística - Programação de Carregamento - Programação Diária",
        ],
        items: [
          {
            title: "Programação Diária",
            href: "/app/management/daily_goal",
            permission: [
              "Logística - Programação de Carregamento - Programação Diária",
            ],
          },
        ],
      },
      {
        title: "Carregamentos em Fornecedores",
        href: "/app/management/transports/records",
        permission: ["Logística - Carregamentos em Fornecedores"],
      },
      {
        title: "Transportadoras",
        permission: [
          "Logística - Transportadoras - Programação de Puxadas",
          "Logística - Transportadoras - Agendamentos",
          "Logística - Transportadoras - Ocorrências",
        ],
        items: [
          {
            title: "Programação de Puxadas",
            href: "/",
            permission: [
              "Logística - Transportadoras - Programação de Puxadas",
            ],
          },
          {
            title: "Agendamentos",
            href: "/app/management/transports/scheduling",
            permission: ["Logística - Transportadoras - Agendamentos"],
          },
          {
            title: "Ocorrências",
            href: "/app/management/occurrences",
            permission: ["Logística - Transportadoras - Ocorrências"],
          },
        ],
      },
      {
        title: "Entrada de Pequenas Coletas",
        href: "/app/management/lots",
        permission: ["Logística - Entrada de Pequenas Coletas"],
      },
      {
        title: "Consulta de Containers",
        href: "/app/data/register/containers",
        permission: ["Logística - Consulta de Containers"],
      },
      {
        title: "Relatórios",
        permission: [
          "Logística - Relatórios - Acompanhamento diário de metas",
          "Logística - Relatórios - Acompanhamento de cabotagem/exportação",
          "Logística - Relatórios - Acompanhamento de NFs de venda",
          "Logística - Relatórios - Carregamento do mês",
          "Logística - Relatórios - Controle de carregamento diário",
          "Logística - Relatórios - Controle de contêineres a chegar",
          "Logística - Relatórios - Controle de entregas na usina",
          "Logística - Relatórios - Controle de processos da Export",
          "Logística - Relatórios - Controle de produtividade",
          "Logística - Relatórios - Localização dos trollers",
          "Logística - Relatórios - Metas do mês",
          "Logística - Relatórios - Ocorrências",
          "Logística - Relatórios - Packlist - cabotagem",
          "Logística - Relatórios - Packlist - exportação",
          "Logística - Relatórios - Puxada do mês - Transnorte",
          "Logística - Relatórios - Status dos cntrs por fornecedor",
          "Logística - Relatórios - Acompanhamento diário de processo",
          "Logística - Relatórios - Movimentação diária",
          "Logística - Relatórios - Controle de custos",
          "Logística - Relatórios - Volume mensal de carregamentos",
          "Logística - Relatórios - Controle mensal de faturamento",
        ],
        items: [
          {
            title: "Acompanhamento diário de metas",
            href: "/app/reports/reports/27",
            permission: [
              "Logística - Relatórios - Acompanhamento diário de metas",
            ],
          },
          {
            title: "Acompanhamento de cabotagem/exportação",
            href: "/app/reports/reports/23",
            permission: [
              "Logística - Relatórios - Acompanhamento de cabotagem/exportação",
            ],
          },
          {
            title: "Acompanhamento de NFs de venda",
            href: "/app/reports/reports/26",
            permission: [
              "Logística - Relatórios - Acompanhamento de NFs de venda",
            ],
          },
          {
            title: "Carregamento do mês",
            href: "/app/reports/reports/15",
            permission: ["Logística - Relatórios - Carregamento do mês"],
          },
          {
            title: "Controle de carregamento diário",
            href: "/app/reports/reports/4",
            permission: [
              "Logística - Relatórios - Controle de carregamento diário",
            ],
          },
          {
            title: "Controle de contêineres a chegar",
            href: "/app/reports/reports/22",
            permission: [
              "Logística - Relatórios - Controle de contêineres a chegar",
            ],
          },
          {
            title: "Controle de entregas na usina",
            href: "/app/reports/reports/21",
            permission: [
              "Logística - Relatórios - Controle de entregas na usina",
            ],
          },
          {
            title: "Controle de processos da Export",
            href: "/app/reports/reports/17",
            permission: [
              "Logística - Relatórios - Controle de processos da Export",
            ],
          },
          {
            title: "Controle de produtividade",
            href: "/app/reports/reports/6",
            permission: ["Logística - Relatórios - Controle de produtividade"],
          },
          {
            title: "Localização dos trollers",
            href: "/app/reports/reports/20",
            permission: ["Logística - Relatórios - Localização dos trollers"],
          },
          {
            title: "Metas do mês",
            href: "/app/reports/reports/19",
            permission: ["Logística - Relatórios - Metas do mês"],
          },
          {
            title: "Ocorrências",
            href: "/app/reports/reports/18",
            permission: ["Logística - Relatórios - Ocorrências"],
          },
          {
            title: "Packlist - cabotagem",
            href: "/app/reports/reports/14",
            permission: ["Logística - Relatórios - Packlist - cabotagem"],
          },
          {
            title: "Packlist - exportação",
            href: "/app/reports/reports/1",
            permission: ["Logística - Relatórios - Packlist - exportação"],
          },
          {
            title: "Puxada do mês - Transnorte",
            href: "/app/reports/reports/12",
            permission: ["Logística - Relatórios - Puxada do mês - Transnorte"],
          },
          {
            title: "Status dos cntrs por fornecedor",
            href: "/app/reports/reports/25",
            permission: [
              "Logística - Relatórios - Status dos cntrs por fornecedor",
            ],
          },
          {
            title: "Acompanhamento diário de processo",
            href: "/app/reports/reports/2",
            permission: [
              "Logística - Relatórios - Acompanhamento diário de processo",
            ],
          },
          {
            title: "Movimentação diária",
            href: "/app/reports/reports/5",
            permission: ["Logística - Relatórios - Movimentação diária"],
          },
          {
            title: "Controle de custos",
            href: "/app/reports/reports/11",
            permission: ["Logística - Relatórios - Controle de custos"],
          },
          {
            title: "Volume mensal de carregamentos",
            href: "/app/reports/reports/13",
            permission: [
              "Logística - Relatórios - Volume mensal de carregamentos",
            ],
          },
          {
            title: "Controle mensal de faturamento",
            href: "/app/reports/reports/16",
            permission: [
              "Logística - Relatórios - Controle mensal de faturamento",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Financeiro",
    icon: DollarSign,
    permission: [
      "Financeiro - Nota Fiscal de Vendas",
      "Financeiro - Contas a Pagar",
      "Financeiro - Contas a Receber",
      "Financeiro - Adiantamento de Sucata",
      "Financeiro - Relatórios",
      "Financeiro - Relatório Semanal",
      "Financeiro - Dashboard"
    ],
    items: [
      {
        title: "Dashboard",
        href: "/app/financial/dashboard",
        permission: ["Financeiro - Dashboard"],
      },
      {
        title: "Nota Fiscal de Vendas",
        href: "/app/financial/sales_invoice",
        permission: ["Financeiro - Nota Fiscal de Vendas"],
      },
      {
        title: "Contas a Pagar",
        href: "/app/financial/contas_a_pagar",
        permission: ["Financeiro - Contas a Pagar"],
      },
      {
        title: "Contas a Receber",
        href: "/app/financial/contas_a_receber",
        permission: ["Financeiro - Contas a Receber"],
      },
      {
        title: "Adiantamento de Sucata",
        href: "/app/financial/adiantamento_sucata/1",
        permission: ["Financeiro - Adiantamento de Sucata"],
      },
      {
        title: "Relatórios",
        href: "/app/financial/reports",
        permission: ["Financeiro - Relatórios"],
      },
      {
        title: "Relatório Semanal",
        href: "/app/financial/report-weekly",
        permission: ["Financeiro - Relatório Semanal"],
      },
    ],
  },
  {
    title: "Compras",
    icon: ShoppingCart,
    permission: [
      "Compras - Pedidos",
      "Compras - Aprovação de Pedidos",
      "Compras - Conferência de Pedidos",
    ],
    items: [
      {
        title: "Pedidos",
        href: "/app/shopping/orders",
        permission: ["Compras - Pedidos"],
      },
      {
        title: "Aprovação de Pedidos",
        href: "/app/shopping/approval",
        permission: ["Compras - Aprovação de Pedidos"],
      },
      {
        title: "Conferência de Pedidos",
        href: "/app/shopping/conference",
        permission: ["Compras - Conferência de Pedidos"],
      },
    ],
  },
  {
    title: "Cadastros",
    icon: PlusSquare,
    permission: [
      "Cadastros - Transportadoras",
      "Cadastros - Destinos",
      "Cadastros - Bases",
      "Cadastros - Prestadores de Serviços - Fornecedores",
      "Cadastros - Prestadores de Serviços - Outros Locais",
      "Cadastros - Financeiro - Tipos",
      "Cadastros - Financeiro - Naturezas",
      "Cadastros - Frotas",
      "Cadastros - Trollers",
      "Cadastros - Armadores",
      "Cadastros - Navios",
      "Cadastros - Materiais",
      "Cadastros - Usuários",
      "Cadastros - Clientes",
      "Cadastros - Clientes da Transnorte",
      "Cadastros - Empresas",
      "Cadastros - Itens",
      "Cadastros - Atividades",
      "Cadastros - Perfis",
    ],
    items: [
      {
        title: "Transportadoras",
        href: "/app/data/register/carriers",
        permission: ["Cadastros - Transportadoras"],
      },
      {
        title: "Destinos",
        href: "/app/data/register/destinations",
        permission: ["Cadastros - Destinos"],
      },
      {
        title: "Bases",
        href: "/app/data/register/places",
        permission: ["Cadastros - Bases"],
      },
      {
        title: "Prestadores de Serviços",
        permission: [
          "Cadastros - Prestadores de Serviços - Fornecedores",
          "Cadastros - Prestadores de Serviços - Outros Locais",
        ],
        items: [
          {
            title: "Fornecedores",
            href: "/app/data/register/providers",
            permission: ["Cadastros - Prestadores de Serviços - Fornecedores"],
          },
          {
            title: "Outros Locais",
            href: "/app/data/register/locations",
            permission: ["Cadastros - Prestadores de Serviços - Outros Locais"],
          },
        ],
      },
      {
        title: "Financeiro",
        permission: [
          "Cadastros - Financeiro - Tipos",
          "Cadastros - Financeiro - Naturezas",
        ],
        items: [
          {
            title: "Tipos",
            href: "/app/financial/register/types",
            permission: ["Cadastros - Financeiro - Tipos"],
          },
          {
            title: "Naturezas",
            href: "/app/financial/register/natures",
            permission: ["Cadastros - Financeiro - Naturezas"],
          },
        ],
      },
      {
        title: "Frotas",
        href: "/app/data/register/fleets",
        permission: ["Cadastros - Frotas"],
      },
      {
        title: "Trollers",
        href: "/app/data/register/trollers",
        permission: ["Cadastros - Trollers"],
      },
      {
        title: "Armadores",
        href: "/app/data/register/shipowners",
        permission: ["Cadastros - Armadores"],
      },
      {
        title: "Navios",
        href: "/app/data/register/ships",
        permission: ["Cadastros - Navios"],
      },
      {
        title: "Materiais",
        href: "/app/data/register/materials",
        permission: ["Cadastros - Materiais"],
      },
      {
        title: "Usuários",
        href: "/app/management/users",
        permission: ["Cadastros - Usuários"],
      },
      {
        title: "Clientes",
        href: "/app/data/register/clients",
        permission: ["Cadastros - Clientes"],
      },
      {
        title: "Clientes da Transnorte",
        href: "/app/data/register/customers",
        permission: ["Cadastros - Clientes da Transnorte"],
      },
      {
        title: "Empresas",
        href: "/app/data/register/companies-full",
        permission: ["Cadastros - Empresas"],
      },
      {
        title: "Itens",
        href: "/app/data/register/items",
        permission: ["Cadastros - Itens"],
      },
      {
        title: "Atividades",
        href: "/app/data/register/activities",
        permission: ["Cadastros - Atividades"],
      },
      {
        title: "Perfis",
        href: "/app/data/register/profiles",
        permission: ["Cadastros - Perfis"],
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0, user }) {
  return (
    <List disablePadding>
      {items
        .filter((item) =>
          item.permission.some((v) => user?.permissions.includes(v))
        )
        .reduce(
          (acc, item) =>
            reduceChildRoutes({
              acc,
              item,
              pathname,
              depth,
              user,
            }),
          []
        )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, user }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(user?.userProvider ? true : open)}
        title={item.title}
        style={{ padding: "5px 0px" }}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          user,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onClose, open }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (open && onClose && matches) {
      onClose();
    }
  }, [location.pathname, matches]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <img alt="Logo" src={"/static/logo.png"} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2} display="flex" alignItems={"center"}>
          <RouterLink to="/app/account">
            <Avatar
              alt="User"
              className={classes.avatar}
              src={user.avatar}
              variant="rounded"
            />
          </RouterLink>
          <Box pl={1}>
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Link component={RouterLink} to="/app/account" underline="none">
              <Typography variant="body2" color="textSecondary">
                {user.profile}
                {user.filterGate ? ` (${user.place_origin})` : ""}
              </Typography>
            </Link>
          </Box>
        </Box>
        <Box paddingX={2} paddingY={0}>
          <List>
            {sections
              .filter((item) =>
                item.permission.some((v) => user.permissions.includes(v))
              )
              .reduce(
                (acc, item) =>
                  reduceChildRoutes({
                    acc,
                    item,
                    pathname: location.pathname,
                    depth: 0,
                    user,
                  }),
                []
              )}
          </List>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onClose}
          open={open}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          onClose={onClose}
          open={open}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default NavBar;
